import { Checkbox, FormControlLabel } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/feedbacks/Dialog';
import Button from 'components/inputs/ButtonM';
import Text from 'components/Text';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import { getPropsbyVal } from 'utils/utils';
import { useSettings } from 'utils/customHooks';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const TaskType = {
  UNLOAD: { key: 'UNLOAD', text: 'Unloading' },
  DEMERGE: { key: 'DEMERGE', text: 'Demerging' },
  ACKNOWLEDGE: { key: 'ACKNOWLEDGE', text: 'Acknowledgement' },
  'CASE SEPARATION': { key: 'CASE SEPARATION', text: 'Case Separation' },
};
const toList = (val) => {
  if (val == undefined) return [];
  if (typeof val === 'object') return val;

  return [val];
};

const TaskStatus = {
  COMPLETED: 'COMPLETED',
  ASSIGNED: 'ASSIGNED',
  UPCOMING: 'UPCOMING',
};

const STATUS = {
  Available: 'Available',
  Busy: 'Busy',
};

const taskDetails = {
  COMPLETED: {
    comp: (
      <div>
        <CheckCircleOutlineIcon fontSize="inherit" /> Completed
      </div>
    ),
    colorCls: 'text-success',
  },
  ASSIGNED: {
    comp: (
      <div>
        <QueryBuilderIcon fontSize="inherit" /> In progress
      </div>
    ),
    colorCls: 'text-orange',
  },
  UPCOMING: {
    comp: (
      <div>
        <QueryBuilderIcon fontSize="inherit" /> Available
      </div>
    ),
    colorCls: 'text-blue',
  },
};

const getTaskNameText = (type) => {
  const res = TaskType[type];
  if (!res) return type;

  return res.text;
};

function TaskManagement() {
  const { date, branch: xdock } = useGState((s) => ({
    date: s.date,
    branch: s.branch,
  }));
  const [openSelector, setOpenSelector] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openMMTaskConfirmation, setOpenMMTaskConfirmation] = useState(false);
  const [taskId, setTaskId] = useState(undefined);
  const [selectedTask, setSelectedTask] = useState();

  const { data: tasks = [], refetch: refetchTasks } = useQuery(
    xdock?.id && ['tasks', xdock.id, date],
    () => fetch(`/falcon/xdock/task/panel/panelTasks?xdockId=${xdock.id}&date=${date}`)
  );
  const { data: scanners = [], refetch: refetchScanners } = useQuery(
    xdock?.id && ['scanners', xdock.id],
    () => fetch(`/falcon/xdock/device/panel/getScanners?xdockId=${xdock.id}`)
  );

  const refetchData = () => {
    refetchTasks();
    refetchScanners();
  };

  const [createMMReturnTask] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: '/falcon/xdock/task/panel/createReturnTask',
        data: {
          xdockId: xdock.id,
          date: date,
        },
      }),
    {
      onSuccess: () => {
        refetchData();
      },
    }
  );

  const [assignTask] = useMutation(
    (deviceIds) =>
      new Promise((resolve) =>
        deviceIds.forEach((deviceId, index) => {
          fetch({
            method: 'POST',
            url: '/falcon/xdock/task/panel/assign',
            data: {
              xdockId: xdock.id,
              taskId: taskId,
              deviceId: deviceId,
            },
          }).then(() => {
            if (index == deviceIds.length - 1) resolve();
          });
        })
      ),
    {
      onSuccess: () => {
        closeDialog();
        refetchData();
      },
    }
  );
  const [unAssignTask] = useMutation(
    (deviceIds) =>
      new Promise((resolve) =>
        deviceIds.forEach((deviceId, index) => {
          fetch({
            method: 'POST',
            url: '/falcon/xdock/task/panel/unassign',
            data: { taskId: selectedTask.id, deviceId },
          }).then(() => {
            if (index == deviceIds.length - 1) resolve();
          });
        })
      ),
    {
      onSuccess: () => {
        refetchData();
      },
    }
  );
  const closeDialog = () => setOpenSelector(false);
  const closeConfirmation = () => setOpenConfirmation(false);

  const handleTaskSelection = (taskId) => {
    setOpenSelector(true);
    setTaskId(taskId);
  };

  const handleTask = useCallback(() => {
    if (!selectedTask) return;

    const deviceIds = scanners
      .filter((sc) => toList(selectedTask.assignedTo).includes(sc.deviceCode))
      .map((sc) => sc.deviceId);

    unAssignTask(deviceIds);
  }, [scanners, selectedTask, unAssignTask]);

  const handleTaskClick = useCallback((task) => {
    if (toList(task.assignedTo).length > 0) {
      setSelectedTask(task);
      setOpenConfirmation(true);
      return;
    }

    handleTaskSelection(task.id);
  }, []);

  const typeWiseTasks = useMemo(
    () =>
      tasks
        .filter((t) => new Date(t.date) >= new Date(date))
        .reduce((acc, item) => {
          acc[item.type] = [...(acc[item.type] || []), item];
          return acc;
        }, {}),
    [date, tasks]
  );

  const modifiedTypeWiseTasks = typeWiseTasks['RETURN SEGREGATION']
    ? {
        'RETURN SEGREGATION': typeWiseTasks['RETURN SEGREGATION'],
        ...typeWiseTasks,
      }
    : { ...typeWiseTasks };

  const renderTasks = useMemo(
    () =>
      Object.keys(modifiedTypeWiseTasks).map((key, index) => (
        <div key={`${modifiedTypeWiseTasks[key]?.id}_${index}`} class="grid my-6">
          <div class="font-semibold mb-2">{getTaskNameText(key)}</div>
          <div class="grid md:grid-cols-3 gap-8">
            {modifiedTypeWiseTasks[key]?.map((item) => (
              <TaskCard
                key={`${item.id}  ${item.midMileId}`}
                data={item}
                onClick={() => handleTaskClick(item)}
              />
            ))}
          </div>
        </div>
      )),
    [handleTaskClick, modifiedTypeWiseTasks]
  );

  return (
    <div class="p-6">
      <Text class="text-3xl">Task Management</Text>
      <div class="grid">
        {renderTasks.length ? renderTasks : <p class="pt-10 text-lg font-normal">No tasks found</p>}
      </div>
      <UnassignConfirmation open={openConfirmation} close={closeConfirmation} onYes={handleTask} />
      <ScannerSelector
        data={scanners.filter((sc) => sc.active)}
        open={openSelector}
        close={closeDialog}
        assignTask={assignTask}
      />
      <ReturnMMTaskConfirmation
        open={openMMTaskConfirmation}
        close={() => setOpenMMTaskConfirmation(false)}
        onYes={() => createMMReturnTask()}
      />
      <div class="fixed right-5 bottom-5">
        <Fab color="primary" aria-label="add" onClick={() => setOpenMMTaskConfirmation(true)}>
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}

function ReturnMMTaskConfirmation({ open, close, onYes }) {
  const onProceed = () => {
    onYes();
    close();
  };
  const { xdock } = useSettings();

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={close}
      classes={{
        paper: 'py-1 px-2 rounded-lg md:w-1/2 lg:w-1/4 align-center',
      }}
    >
      <DialogTitle>
        <p class="text-xl font-bold mb-4 text-center">
          {`Do you want to create ${
            xdock?.enableReturnSegregationInXdock ? 'Return Segregation and' : ''
          } Mid mile return task ?`}
        </p>
      </DialogTitle>
      <DialogContent class="p-6 pt-1 flex flex-1 justify-center" dividers>
        <button
          class={`bg-gray-400 text-theme rounded-lg p-2 px-6 text-sm mt-5 cursor-pointer tooltip mr-3`}
          onClick={close}
        >
          NO
        </button>
        <button
          class={`bg-theme text-white rounded-lg p-2 px-6 text-sm mt-5 cursor-pointer tooltip`}
          onClick={onProceed}
        >
          Yes
        </button>
      </DialogContent>
    </Dialog>
  );
}

function DetailsCard({ title, val }) {
  if (!val) return <></>;

  return (
    <p class="text-xs mt-1 opacity-50">
      {title} :<span class="font-bold">{' ' + val}</span>
    </p>
  );
}

function TaskCard({
  data: { groupId, date, status, type, midMileName, lastMileTripName, assignedTo, palletIds },
  onClick,
}) {
  const details = taskDetails[status];
  const devices = toList(assignedTo);
  const pallets = toList(palletIds);

  return (
    <div class="bg-white rounded-lg p-5 shadow h-fi">
      <div class="flex justify-between">
        <div>
          <Text class="text-sm font-medium text-primary">
            {type === 'UNLOAD' ? 'Unloading' : `Group ${groupId} ${getTaskNameText(type)}`}
          </Text>

          <DetailsCard title="Date" val={date} />
          <DetailsCard title="MM Trip" val={midMileName} />
          <DetailsCard title="LM Trip" val={type === 'UNLOAD' ? undefined : lastMileTripName} />
          <DetailsCard title="Pallets" val={pallets?.join(', ')} />

          {status != TaskStatus.COMPLETED && (
            <div class="flex flex-row items-center justify-between">
              <div
                class={`${
                  devices.length > 0
                    ? 'bg-background text-theme font-bold text-xs'
                    : 'bg-theme text-white'
                } rounded-lg p-2 px-3 text-tiny mt-5 cursor-pointer tooltip`}
                onClick={onClick}
              >
                <Text class="mr-2 float-left">{devices.join(', ') || 'Assign Scanner'}</Text>
                {devices.length > 0 && (
                  <span class="tooltip-text bg-gray-200 px-2 py-1 -mt-10 -ml-12 rounded">
                    Unassign Task
                  </span>
                )}
                <ArrowForwardIosIcon fontSize="inherit" />
              </div>
            </div>
          )}
        </div>
        <div class={`${details.colorCls} flex text-tiny place-items-start`}>{details.comp}</div>
      </div>
    </div>
  );
}

export function UnassignConfirmation({ open, title, close, onYes }) {
  const onProceed = () => {
    onYes();
    close();
  };

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={close}
      classes={{
        paper: 'py-1 px-2 rounded-lg md:w-1/2 lg:w-1/4 align-center',
      }}
    >
      <DialogTitle>
        <p class="text-xl font-bold mb-4 text-center">
          {title || 'This will unassign the task. Do you really want to go proceed?'}
        </p>
      </DialogTitle>
      <DialogContent class="p-6 pt-1 flex flex-1 justify-center" dividers>
        <button
          class={`bg-gray-400 text-theme rounded-lg p-2 px-6 text-sm mt-5 cursor-pointer tooltip mr-3`}
          onClick={close}
        >
          NO
        </button>
        <button
          class={`bg-theme text-white rounded-lg p-2 px-6 text-sm mt-5 cursor-pointer tooltip`}
          onClick={onProceed}
        >
          Yes
        </button>
      </DialogContent>
    </Dialog>
  );
}

function ScannerSelector({ data, open, close, assignTask }) {
  const [filterText, setFilterText] = useState('');
  const [selectedDeviceIds, setSelectedDeviceIds] = useState({});

  if (!data?.length) return <></>;

  const handleDeviceClick = ({ target: { name, checked } }) => {
    setSelectedDeviceIds((preState) => ({
      ...preState,
      [name]: checked,
    }));
  };

  const assignToAllDevice = () => {
    assignTask(getPropsbyVal(selectedDeviceIds, true));
    setSelectedDeviceIds({});
  };

  const statusWiseScanners = data
    .filter((d) => d.deviceCode.includes(filterText) || d.deviceId === filterText.toLowerCase())
    .reduce((acc, item) => {
      const status = item.busy ? STATUS.Busy : STATUS.Available;
      acc[status] = [...(acc[status] || []), item];
      return acc;
    }, {});

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={close}
      classes={{ paper: 'py-1 px-2 rounded-lg md:w-1/2 lg:w-1/4 min-h-1/2 max-h-1/2' }}
    >
      <DialogTitle>
        <p class="text-xl font-bold mb-4">Select Scanner</p>
        <input
          value={filterText}
          onChange={({ target: { value } }) => setFilterText(value.toLocaleUpperCase())}
          placeholder="search"
          type="text"
          class="border-solid border-2 rounded-full text-center w-full p-1 px-6 text-base"
        />
      </DialogTitle>
      <DialogContent class="p-6 pt-1" dividers>
        {Object.keys(statusWiseScanners).length ? (
          Object.keys(STATUS).map((status) => (
            <DeviceItem
              key={Math.random().toString(16)}
              data={statusWiseScanners[status]}
              handleDeviceClick={handleDeviceClick}
              selectedDeviceIds={selectedDeviceIds}
              status={status}
            />
          ))
        ) : (
          <div class="text-sm pt-6 font-semibold">
            <p>{`No results found for "${filterText}"`}</p>
          </div>
        )}
      </DialogContent>
      <DialogActions class="text-left p-6">
        <Button className="rounded-lg" onClick={assignToAllDevice}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeviceItem(props) {
  const { status, data: scs, selectedDeviceIds, handleDeviceClick } = props;

  if (scs == undefined) return <></>;

  return (
    <div>
      <p class="text-sm mt-4 mb-2 font-medium">{status}</p>
      {scs.map((sc, idx) => (
        <div key={sc.deviceCode + idx} class="flex flex-row text-tinny items-center cursor-pointer">
          <Text class={`${status == STATUS.Available ? 'text-success' : 'text-danger'} mr-2`}>
            <FiberManualRecordIcon style={{ fontSize: 10 }} />
          </Text>
          <FormControlLabel
            control={
              <Checkbox
                classes={{ root: 'py-1 text-tertiary' }}
                checked={selectedDeviceIds[sc.deviceId] == true}
                onChange={handleDeviceClick}
                name={sc.deviceId}
              />
            }
            label={<p class="text-base font-bold">{sc.deviceCode}</p>}
          />
        </div>
      ))}
    </div>
  );
}

export default TaskManagement;
