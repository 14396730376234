import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import TaskManagement from 'xdock/TaskManagement';

export default function TaskManagementContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background min-h-full">
          <TaskManagement />
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
